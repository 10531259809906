import { FC, ReactNode } from "react";

import { Box } from "@mui/material";
import Footer from "@/components/wrapper/Footer";
import Header from "@/components/wrapper/Header";
import React from "react";
import { Styles } from "@halftax/ui";

interface WrapperProps {
  children: ReactNode;
}

const PageWrapper: FC<WrapperProps> = ({ children }) => {
  const styles: Styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    content: {
      mt: "6.5rem",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
  };

  return (
    <Box sx={styles.wrapper}>
      <Header />
      <Box sx={styles.content}>{children}</Box>
      <Footer />
    </Box>
  );
};

export default PageWrapper;
