import {
  DependencyList,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";

import { modalContext } from "@halftax/ui";

type UseModal = (
  modal: (props: { hideModal: () => void; isActive: boolean }) => ReactNode,
  dependencyList?: DependencyList,
) => {
  showModal: () => void;
  hideModal: () => void;
  isActive: boolean;
};

export const useModal: UseModal = (modal, dependencyList = []) => {
  const modalId = useId();
  const [isActive, setIsActive] = useState(false);
  const { addModal, removeModal } = useContext(modalContext);

  const component = useMemo(
    () => modal,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencyList,
  );

  const showModal = useCallback(() => setIsActive(true), []);
  const hideModal = useCallback(() => setIsActive(false), []);

  useEffect(
    () => {
      if (isActive) {
        addModal({ modal: component({ hideModal, isActive }), id: modalId });
      }

      return () => removeModal(modalId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [component, isActive],
  );

  return {
    showModal,
    hideModal,
    isActive,
  };
};
