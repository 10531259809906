import type { GatsbySSR } from "gatsby";
import PageWrapper from "@/components/wrapper/PageWrapper";
import Providers from "@/components/Providers";
import React from "react";

import "@fontsource-variable/inter";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => {
  return (
    <Providers>
      <PageWrapper>{element}</PageWrapper>
    </Providers>
  );
};
