import { Modal, ModalContext, modalContext } from "@halftax/ui";
import React, { FC, ReactNode, useCallback, useState } from "react";

interface ModalProviderProps {
  children?: ReactNode;
}

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modals, setModals] = useState<Record<string, ReactNode>>({});

  const addModal = useCallback<ModalContext["addModal"]>(({ modal, id }) => {
    setModals((prev) => ({ ...prev, [id]: modal }));
  }, []);

  const removeModal = useCallback<ModalContext["removeModal"]>((key) => {
    setModals((prev) => {
      const modalsClone = { ...prev };
      delete modalsClone[key];
      return modalsClone;
    });
  }, []);

  const modalsRender = Object.entries(modals).map(([key, component]) => (
    <Modal key={key}>{component}</Modal>
  ));

  return (
    <modalContext.Provider value={{ addModal, removeModal }}>
      {children}
      {modalsRender}
    </modalContext.Provider>
  );
};
