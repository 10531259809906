/* eslint-disable max-lines */

import React from "react";

import { amber, orange } from "@mui/material/colors";

import { SystemStyleObject } from "@mui/system";
import { Theme, ThemeOptions } from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";
import createTheme from "@mui/material/styles/createTheme";
import { WindowClose } from "mdi-material-ui";

// for sx type hints on const styles = {...}
export type Styles = Record<
  string,
  SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
>;

export type TypographyOptions = NonNullable<ThemeOptions["typography"]>;

export const palette = createPalette({
  primary: {
    lightest: amber[50],
    light: amber[100],
    main: amber.A200,
    accent: amber.A400,
    dark: amber[600],
  },
  yellow: {
    100: "#FFF9E5",
    200: "#FFECB3",
    300: "#FFD54F",
    400: "#FFCA28",
    500: "#FFC400",
    600: "#FFB300",
    700: "#FFA000",
    800: "#FF8F00",
    900: "#FF6F00",
  },
  secondary: {
    light: "#b3c5cc",
    medium: "#5d6b71",
    main: "#232f34",
    dark: "#151c1f",
    contrastText: "rgba(255, 255, 255, 0.87)",
  },
  grey: {
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D1D4DB",
    400: "#9CA2AF",
    500: "#6B7280",
    600: "#4B5363",
    700: "#373F51",
    800: "#1F2737",
    900: "#111827",
  },
  error: {
    light: "#ef5350",
    main: "#d32f2f",
    dark: "#c62828",
  },
  warning: {
    light: orange[500],
    main: "#ed6c02",
    dark: "#e65100",
  },
  info: {
    light: "#03a9f4",
    main: "#0288d1",
    dark: "#01579b",
  },
  success: {
    light: "#4caf50",
    main: "#2e7d32",
    dark: "#1b5e20",
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.6)",
    disabled: "rgba(0, 0, 0, 0.38)",
  },
  whiteText: {
    primary: "rgba(255, 255, 255, 0.87)",
    secondary: "rgba(255, 255, 255, 0.6)",
    disabled: "rgba(255, 255, 255, 0.38)",
  },
  background: {
    default: "#f6f6f4",
  },
  common: {
    black: "#000",
    white: "#fff",
  },
  action: {
    active: "rgba(0, 0, 0, 0.56)",
    disabled: "rgba(0, 0, 0, 0.26)",
    hover: "rgba(0, 0, 0, 0.04)",
  },
  other: {
    border: "#ebebeb",
    divider: "#ebebeb",
  },
  linkButton: {
    main: "#d9eff8",
  },
});

export const typographyAdmin: TypographyOptions = {
  fontFamily: ["Inter", "sans-serif"].join(","),
  h1: {
    fontWeight: 300,
    fontSize: "6rem",
    lineHeight: "7rem",
    letterSpacing: "-1.5px",
  },
  h2: {
    fontWeight: 300,
    fontSize: "3.75rem",
    lineHeight: "4.5rem",
    letterSpacing: "-0.5px",
  },
  h3: {
    fontWeight: 400,
    fontSize: "3rem",
    lineHeight: "3.5rem",
  },
  h4: {
    fontWeight: 400,
    fontSize: "2.125rem",
    lineHeight: "2.625rem",
    letterSpacing: "-0.25px",
  },
  h5: {
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  h6: {
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: "2rem",
    letterSpacing: "0.15px",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.75rem",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
    letterSpacing: "0.15px",
  },
  body1: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.15px",
  },
  body2: {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    letterSpacing: "0.15px",
  },
  caption: {
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    letterSpacing: "0.4px",
  },
  overline: {
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    letterSpacing: "1px",
  },
};

export const typographyLanding: TypographyOptions = {
  fontFamily: ["Inter Variable", "sans-serif"].join(","),
  h1: {
    fontWeight: 600,
    fontSize: "5rem",
    lineHeight: "7.5rem",
  },
  h2: {
    fontWeight: 600,
    fontSize: "3rem",
    lineHeight: "4rem",
  },
  h3: {
    fontWeight: 600,
    fontSize: "2.5rem",
    lineHeight: "3.5rem",
  },
  h4: {
    fontWeight: 600,
    fontSize: "2rem",
    lineHeight: "3rem",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "2.5rem",
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: "2rem",
  },
  body1: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "2rem",
  },
  body2: {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  caption: {
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
};

export const getTheme = (variant: "admin" | "landing"): Theme => {
  const typography = {
    admin: typographyAdmin,
    landing: typographyLanding,
  };

  const theme = createTheme({
    palette,
    typography: typography[variant],
  });

  return createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            minHeight: "100vh",
            backgroundColor: variant === "admin" ? palette.secondary.main : palette.common.white,
          },
        },
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: "outlined" },
            style: {
              backgroundColor: palette.common.white,
              borderRadius: "0.5rem",
              border: `1px solid ${palette.other.border}`,
              boxShadow: "0px 0px 5px rgba(213, 213, 213, 0.25)",
              width: "100%",
              height: "100%",
            },
          },
        ],
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: theme.spacing(1),
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: palette.primary.main,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: theme.spacing(3),
          },
        },
      },

      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: theme.palette.text.primary,
          },
        },
      },

      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "1rem 1.5rem",
          },
        },
      },

      MuiButton: {
        variants: [
          {
            props: { variant: "landing" },
            style: {
              backgroundColor: palette.yellow[500],
              textTransform: "uppercase",
              fontWeight: 600,
              letterSpacing: "1.5px",

              ":hover": {
                backgroundColor: palette.yellow[600],
              },

              [theme.breakpoints.up("xs")]: {
                width: "100%",
                padding: "1rem",
              },

              [theme.breakpoints.up("sm")]: {
                width: "auto",
                padding: "1rem 2.5rem",
              },
            },
          },
        ],
        defaultProps: {
          disableElevation: true,
          variant: "contained",
          color: "secondary",
          size: "small",
        },
        styleOverrides: {
          root: {
            fontWeight: 500,
            borderRadius: "2rem",
            textTransform: "none",
            letterSpacing: "0.15px",
          },
          sizeSmall: {
            padding: "0.25rem 0.75rem",
            lineHeight: "1.375rem",
          },
          sizeMedium: {
            padding: "0.375rem 1rem",
            lineHeight: "1.5rem",
          },
          sizeLarge: {
            padding: "0.5rem 1.375rem",
            lineHeight: "1.625rem",
          },
          iconSizeSmall: {
            "& .MuiSvgIcon-root": {
              width: "1.25rem",
              height: "1.25rem",
            },
          },
          iconSizeLarge: {
            "& .MuiSvgIcon-root": {
              width: "1.5rem",
              height: "1.5rem",
            },
          },
          outlinedSizeSmall: {
            height: "1.875rem",
          },
          outlinedSizeMedium: {
            height: "2.25rem",
          },
          outlinedSizeLarge: {
            height: "2.625rem",
          },
        },
      },

      MuiButtonGroup: {
        variants: [
          {
            props: { variant: "icon" },
            style: {
              background: palette.common.white,
              borderRadius: "0.5rem 0.5rem 0 0",
              boxShadow: "0px 0px 5px rgba(213, 213, 213, 0.25)",
              borderColor: palette.other.border,
              borderStyle: "solid",
              borderWidth: "1px 1px 0 1px",
              height: "calc(2.25rem - 1px)",

              "& .MuiIconButton-root": {
                borderRadius: 0,

                // dividers between multiple icon buttons
                ":not(:first-of-type)::after": {
                  content: "''",
                  position: "absolute",
                  top: "20%",
                  left: 0,
                  height: "60%",
                  borderLeft: `1px solid ${palette.other.divider}`,
                },

                ":first-of-type": {
                  borderStartStartRadius: "0.5rem",
                },

                ":last-of-type": {
                  borderStartEndRadius: "0.5rem",
                },
              },
            },
          },
        ],
      },

      MuiIconButton: {
        styleOverrides: {
          sizeSmall: {
            padding: "0.3125rem",
          },
          sizeMedium: {
            padding: "0.375rem",
          },
          sizeLarge: {
            padding: "0.3125rem",
          },
        },
      },

      MuiAvatar: {
        variants: [
          {
            props: { variant: "contact" },
            style: {
              width: "3rem",
              height: "3rem",
              backgroundColor: palette.secondary.main,
              color: palette.primary.main,
              fontWeight: 500,
              fontSize: "1.25rem",
              lineHeight: "1.25rem",
            },
          },
          {
            props: { variant: "notification" },
            style: {
              width: 48,
              height: 48,
              borderRadius: "50%",
              backgroundColor: palette.primary.main,
              textTransform: "uppercase",
              textAlign: "center",
              lineHeight: "48px",
              fontSize: 20,
              color: palette.common.white,
              fontWeight: 500,
            },
          },
        ],
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
            fontWeight: 500,
            fontSize: "1rem",
            lineHeight: "1.75rem",
            letterSpacing: "0.15px",
            position: "static",
            transform: "none",
            marginBottom: theme.spacing(1),

            "&.Mui-focused": {
              color: palette.text.primary,
            },
          },
        },
      },

      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },

      MuiOutlinedInput: {
        defaultProps: {},
        styleOverrides: {
          notchedOutline: {
            top: 0,
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "0.5rem",
            transition: theme.transitions.create("border-color"),
          },
          root: {
            "& fieldset legend": {
              display: "none",
            },

            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgba(0, 0, 0, 0.48)",
              },
            },
          },
          input: {
            boxSizing: "border-box",
            height: "2.5rem",
            borderRadius: "0.5rem",
            padding: "0.5rem 0.75rem",
            transition: theme.transitions.create(["border-color", "box-shadow", "color"]),

            "& .MuiSvgIcon-root": {
              color: palette.action.active,
            },

            "&::placeholder": {
              color: palette.text.secondary,
            },
          },
          adornedStart: {
            paddingLeft: "0.5rem",
          },
          inputAdornedStart: {
            marginLeft: "0.25rem",
          },
          adornedEnd: {
            paddingRight: "0.5rem",
          },
          inputAdornedEnd: {
            marginRight: "0.25rem",
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: theme.spacing(3),
          },
        },
      },

      MuiLink: {
        defaultProps: {
          underline: "none",
          color: "info.main",
          variant: "body2",
        },
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },

      MuiTableContainer: {
        styleOverrides: {
          root: {
            padding: "1rem 1.5rem",
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "0.5rem 0",
            borderBottom: `1px solid ${palette.other.divider}`,
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            ":last-of-type": {
              ".MuiTableCell-root": {
                borderBottom: "none",
              },
            },
          },
        },
      },

      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: palette.other.divider,
          },
        },
      },

      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              padding: "0.5rem 0.75rem",
            },
          },
          inputRoot: {
            padding: 0,
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              borderRadius: "0.5rem",
              border: `1px solid rgba(0, 0, 0, 0.16)`,
              boxShadow: "0px 0px 12px rgba(102, 102, 102, 0.25)",
            },
          },
        },
      },

      MuiChip: {
        defaultProps: {
          deleteIcon: <WindowClose />,
        },
        variants: [
          {
            props: { variant: "filled" },
            style: {
              backgroundColor: theme.palette.secondary.light,

              "& .MuiChip-avatar": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.light,
              },

              "& .MuiChip-deleteIcon": {
                color: theme.palette.secondary.main,
              },
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              border: `1px solid ${theme.palette.secondary.medium}`,

              "& .MuiChip-avatar": {
                backgroundColor: theme.palette.secondary.medium,
                color: theme.palette.common.white,
              },

              "& .MuiChip-deleteIcon": {
                color: theme.palette.secondary.medium,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            borderRadius: "0.5rem",
          },
          sizeMedium: {
            height: "2rem",
          },
          sizeSmall: {
            height: "1.5rem",
          },
        },
      },
    },
  });
};
