import React, { forwardRef } from "react";

import { PatternFormat } from "react-number-format";

interface InputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const PhoneInput = forwardRef<typeof PatternFormat, InputProps>(function PhoneInput(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      format="+1 (###) ###-####"
      mask="_"
      getInputRef={ref}
      valueIsNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

export const PhoneOutput = (value: string) => {
  const format = value?.length === 10 ? "+1 (###) ###-####" : "+# (###) ###-####";

  return <PatternFormat displayType="text" value={value} format={format} />;
};

export const cleanPhone = (phone?: string) => {
  if (phone != null && phone !== "") {
    let cleanedPhone = phone.replace(/[^0-9.]/g, "");
    if (cleanedPhone.length === 11) {
      return cleanedPhone.slice(1);
    } else {
      return cleanedPhone;
    }
  } else {
    return phone;
  }
};
