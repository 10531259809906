import { ReactNode, createContext } from "react";

export interface ModalContext {
  addModal: (props: { modal: ReactNode; id: string }) => void;
  removeModal: (key: string) => void;
}

export const modalContext = createContext<ModalContext>({
  addModal: () => {},
  removeModal: () => {},
});
