import { CssBaseline, ThemeProvider } from "@mui/material";
import { ModalProvider, getTheme } from "@halftax/ui";
import React, { FC, ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

const Providers: FC<Props> = ({ children }) => (
  <ThemeProvider theme={getTheme("landing")}>
    <CssBaseline />
    <ModalProvider>{children}</ModalProvider>
  </ThemeProvider>
);

export default Providers;
