import { Box, Typography, useTheme } from "@mui/material";

import React from "react";
import { Styles } from "@halftax/ui";
import logo from "@/images/common/logo-gold.svg";

const Footer = () => {
  const theme = useTheme();

  const styles: Styles = {
    footer: {
      height: "29rem",
      pt: 6,
      backgroundColor: theme.palette.grey[900],
      display: "flex",
      justifyContent: "center",
    },
    footerLogo: {
      "& img": { width: "12.5rem" },
    },
    footerBottom: {
      height: "3rem",
      pt: 1,
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.yellow[500],
      textAlign: "center",
    },
  };

  return (
    <Box>
      <Box sx={styles.footer}>
        <Box sx={styles.footerLogo}>
          <img src={logo} alt="Halftax Logo" />
        </Box>
      </Box>
      <Box sx={styles.footerBottom}>
        <Typography variant="body1">© HalfTax {new Date().getFullYear()}</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
