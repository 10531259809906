// U. S. states and territories by state code
export enum StateCode {
  Alabama = "AL",
  Alaska = "AK",
  AmericanSamoa = "AS",
  Arizona = "AZ",
  Arkansas = "AR",
  California = "CA",
  Colorado = "CO",
  Connecticut = "CT",
  Delaware = "DE",
  DistrictOfColumbia = "DC",
  Florida = "FL",
  Georgia = "GA",
  Guam = "GU",
  Hawaii = "HI",
  Idaho = "ID",
  Illinois = "IL",
  Indiana = "IN",
  Iowa = "IA",
  Kansas = "KS",
  Kentucky = "KY",
  Louisiana = "LA",
  Maine = "ME",
  MarshallIslands = "MH",
  Maryland = "MD",
  Massachusetts = "MA",
  Michigan = "MI",
  Minnesota = "MN",
  Mississippi = "MS",
  Missouri = "MO",
  Montana = "MT",
  Nebraska = "NE",
  Nevada = "NV",
  NewHampshire = "NH",
  NewJersey = "NJ",
  NewMexico = "NM",
  NewYork = "NY",
  NorthCarolina = "NC",
  NorthDakota = "ND",
  NorthernMarianaIslands = "MP",
  Ohio = "OH",
  Oklahoma = "OK",
  Oregon = "OR",
  Palau = "PW",
  Pennsylvania = "PA",
  PuertoRico = "PR",
  RhodeIsland = "RI",
  SouthCarolina = "SC",
  SouthDakota = "SD",
  Tennessee = "TN",
  Texas = "TX",
  Utah = "UT",
  Vermont = "VT",
  VirginIslands = "VI",
  Virginia = "VA",
  Washington = "WA",
  WestVirginia = "WV",
  Wisconsin = "WI",
  Wyoming = "WY",
}

export const stateLabels: {
  [T in StateCode]: string;
} = {
  [StateCode.Alabama]: "Alabama",
  [StateCode.Alaska]: "Alaska",
  [StateCode.AmericanSamoa]: "American Samoa",
  [StateCode.Arizona]: "Arizona",
  [StateCode.Arkansas]: "Arkansas",
  [StateCode.California]: "California",
  [StateCode.Colorado]: "Colorado",
  [StateCode.Connecticut]: "Connecticut",
  [StateCode.Delaware]: "Delaware",
  [StateCode.DistrictOfColumbia]: "District of Columbia",
  [StateCode.Florida]: "Florida",
  [StateCode.Georgia]: "Georgia",
  [StateCode.Guam]: "Guam",
  [StateCode.Hawaii]: "Hawaii",
  [StateCode.Idaho]: "Idaho",
  [StateCode.Illinois]: "Illinois",
  [StateCode.Indiana]: "Indiana",
  [StateCode.Iowa]: "Iowa",
  [StateCode.Kansas]: "Kansas",
  [StateCode.Kentucky]: "Kentucky",
  [StateCode.Louisiana]: "Louisiana",
  [StateCode.Maine]: "Maine",
  [StateCode.MarshallIslands]: "Marshall Islands",
  [StateCode.Maryland]: "Maryland",
  [StateCode.Massachusetts]: "Massachusetts",
  [StateCode.Michigan]: "Michigan",
  [StateCode.Minnesota]: "Minnesota",
  [StateCode.Mississippi]: "Mississippi",
  [StateCode.Missouri]: "Missouri",
  [StateCode.Montana]: "Montana",
  [StateCode.Nebraska]: "Nebraska",
  [StateCode.Nevada]: "Nevada",
  [StateCode.NewHampshire]: "New Hampshire",
  [StateCode.NewJersey]: "New Jersey",
  [StateCode.NewMexico]: "New Mexico",
  [StateCode.NewYork]: "New York",
  [StateCode.NorthCarolina]: "North Carolina",
  [StateCode.NorthDakota]: "North Dakota",
  [StateCode.NorthernMarianaIslands]: "Northern Mariana Islands",
  [StateCode.Ohio]: "Ohio",
  [StateCode.Oklahoma]: "Oklahoma",
  [StateCode.Oregon]: "Oregon",
  [StateCode.Palau]: "Palau",
  [StateCode.Pennsylvania]: "Pennsylvania",
  [StateCode.PuertoRico]: "Puerto Rico",
  [StateCode.RhodeIsland]: "Rhode Island",
  [StateCode.SouthCarolina]: "South Carolina",
  [StateCode.SouthDakota]: "South Dakota",
  [StateCode.Tennessee]: "Tennessee",
  [StateCode.Texas]: "Texas",
  [StateCode.Utah]: "Utah",
  [StateCode.Vermont]: "Vermont",
  [StateCode.VirginIslands]: "Virgin Islands",
  [StateCode.Virginia]: "Virginia",
  [StateCode.Washington]: "Washington",
  [StateCode.WestVirginia]: "West Virginia",
  [StateCode.Wisconsin]: "Wisconsin",
  [StateCode.Wyoming]: "Wyoming",
};
