import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon, Menu as MenuIcon } from "mdi-material-ui";
import React, { useState } from "react";

import { Link } from "gatsby";
import { Styles } from "@halftax/ui";
import logo from "@/images/common/logo.svg";

const pages = [
  {
    tabLabel: "Home",
    link: "/",
  },
  {
    tabLabel: "Commercial",
    link: "/commercial/",
  },
  {
    tabLabel: "Appeals",
    link: "/appeals/",
  },
  {
    tabLabel: "About Us",
    link: "/about-us/",
  },
  {
    tabLabel: "Pricing",
    link: "/pricing/",
  },
  {
    tabLabel: "Contact Us",
    link: "/contact-us/",
  },
];

const Header = () => {
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const displays: Styles = {
    smallDisplay: {
      display: { xs: "flex", lg: "none" },
    },
    largeDisplay: {
      display: { xs: "none", lg: "flex" },
    },
  };

  const styles: Styles = {
    appbar: {
      backgroundColor: "#FFFFFF",
      height: "6.5rem",
      justifyContent: "center",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      ...displays.largeDisplay,
      fontWeight: 600,
      px: 3,
      py: 1.5,
    },
    icon: {
      fontSize: "3rem",
      color: theme.palette.grey[900],
    },
    drawerHeader: {
      px: 4,
      py: 2,
      justifyContent: "space-between",
      display: "flex",
    },
    menuItem: {
      bgcolor: "transparent",
      color: theme.palette.grey[600],
      display: "flex",
      justifyContent: "center",
      whiteSpace: "nowrap",

      "&.Mui-selected": {
        bgcolor: "transparent",
        color: theme.palette.grey[900],

        "&::after": {
          content: "''",
          position: "absolute",
          width: "2.5rem",
          top: "100%",
          borderBottom: `2px solid ${theme.palette.grey[900]}`,
        },
      },

      "&:hover, &.Mui-selected:hover": {
        bgcolor: "transparent",
      },
    },
    menuItemText: {
      display: "flex",
      justifyContent: "center",
    },
  };

  return (
    <AppBar position="fixed" sx={styles.appbar}>
      <Container maxWidth="xl">
        <Toolbar sx={styles.toolbar}>
          <Box
            component={Link}
            onClick={() => setDrawerOpen(false)}
            to="/"
            sx={{ cursor: "pointer" }}
          >
            <img src={logo} alt="Halftax Logo" />
          </Box>
          <Box sx={displays.largeDisplay}>
            <List style={{ display: "flex", flexDirection: "row" }}>
              {pages.map(({ tabLabel, link }) => (
                <ListItem
                  key={tabLabel}
                  disablePadding
                  sx={{
                    minWidth: "7rem",
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={link}
                    sx={styles.menuItem}
                    activeClassName="Mui-selected"
                  >
                    <ListItemText
                      sx={styles.menuItemText}
                      primary={
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {tabLabel}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Button variant="contained" sx={styles.button} component={Link} to="/application">
            <Typography variant="body2" sx={{ textTransform: "uppercase", fontWeight: 600 }}>
              File an appeal
            </Typography>
          </Button>
          <Box sx={displays.smallDisplay}>
            <IconButton size="large" onClick={() => setDrawerOpen(true)}>
              <MenuIcon sx={styles.icon} />
            </IconButton>
            <Drawer anchor="top" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <Box sx={styles.drawerHeader}>
                <img src={logo} alt="Halftax Logo" />
                <Box>
                  <IconButton size="large" onClick={() => setDrawerOpen(false)}>
                    <CloseIcon sx={styles.icon} />
                  </IconButton>
                </Box>
              </Box>
              <List>
                {pages.map(({ tabLabel, link }) => (
                  <ListItem key={tabLabel} disablePadding>
                    <ListItemButton
                      component={Link}
                      to={link}
                      sx={styles.menuItem}
                      activeClassName="Mui-selected"
                      onClick={() => setDrawerOpen(false)}
                      disableRipple
                    >
                      <ListItemText
                        sx={styles.menuItemText}
                        primary={
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {tabLabel}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
